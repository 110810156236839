import { useEffect, useState, useContext } from 'react';
import { useParams} from "react-router-dom";
import { Button, Form} from 'react-bootstrap';
import UserContext from './UserContext';
import MovieDetails from './MovieDetails';
import AddTipModal from '../Components/AddTipModal';
import { useApi } from '../api/useApi';
import { fetchResource, postResource } from '../api/api';
import { getUserNameById } from '../functions.js';
import { DragDropContext,Droppable, Draggable  } from 'react-beautiful-dnd';


// Code copied from: https://codesandbox.io/s/-w5szl?file=/src/index.js:1066-1081
const grid = 4;
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 450
});
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `10 10 ${grid}px 10`,

  // change background colour if dragging
  background: isDragging ? "lightblue" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const List = () => {
  const [userContext] = useContext(UserContext);
  const [list, setList] = useApi(() => fetchResource('getList/' + id));
  const [titles, setTitles] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState('');
  const [deleteTitleFromListResult, setDeleteTitleFromListResult] = useApi(() => postResource('deleteTitleFromList', { listId: id, titleId: selectedMovie }));
  const {id} = useParams();
  const [planBoard, setPlanBoard] = useState(false);
  const [planCols, setPlanCols] = useState(['Te plannen','Door', 'Reserve', 'Niet door']);
  const [admin, setAdmin] = useState(true);
  const [updateTstamp, setUpdateTstamp] = useState( Date.now());

  useEffect(()=>{
    if(id){
      setList();
    }
    if(userContext.userName==='Hendrik'){
      setAdmin(true);
    }
  },[]);

  useEffect(()=>{
    if(selectedMovie){
      setDeleteTitleFromListResult();
    }
  },[selectedMovie]);

  useEffect(()=>{
    if(deleteTitleFromListResult.isSuccess){
      setList();
    }
  },[deleteTitleFromListResult]);

  useEffect(()=>{
    // Set the Column field per movie.

    if(list.data){
      var tempList = list.data;
      var tempExtraInfo = [];
      var extraInfo = [];
      if(list.data.ExtraInfo){
        extraInfo = JSON.parse(list.data.ExtraInfo);
      }

      tempList.Movies.forEach((movie, index)=>{
        tempExtraInfo = extraInfo.filter(title => title.titleId===movie.Id);
        if(tempExtraInfo.length>0 ){
          tempList.Movies[index].Column=tempExtraInfo[0].column;
        } else {
          tempList.Movies[index].Column='Te plannen';
        }
      })

      setTitles(tempList.Movies);
    }
  },[list]);

  const updatePlanBoard = ((e)=>{
    setPlanBoard(e.target.checked);
  });

  const savePlan = ((cols)=>{
    postResource('updateListExtraInfo', { listId: id, extraInfo: JSON.stringify(cols) });
  })

  function onDragEnd(result) {
    if(admin){
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }
      var titlesTemp = titles;
      if (source.droppableId !== destination.droppableId) {
        let pos = titles.map(e => e.Id.toString()).indexOf(result.draggableId);
        titlesTemp[pos].Column = destination.droppableId;
        let cols = titlesTemp.map(e => ({titleId:e.Id, column:e.Column}));
        setTitles(titlesTemp);
        savePlan(cols);
      }
    }
  }

 //{ admin ? planBoardSelect(Movie.Id, Movie.Column) : <></>}
  const expandedList = (()=>{
    return(
      <div >
      {titles.map((Movie, index) => (
        <div key={'WLD'+index}>
          <MovieDetails key={'WLMD'+index} movie={Movie} />
          {planBoardSelect(Movie.Id, Movie.Column)}

          <p>Toegevoegd door: {getUserNameById(userContext.allUsers, Movie.CBy)} op {new Date(Date.parse(Movie.CDate)).toLocaleDateString('nl-NL')}</p>
          { userContext.lists && userContext.userId ? <div className="mb-2"><AddTipModal movie={Movie}/></div> : <></>}
          {Movie.CBy===userContext.userId ? <Button key={'WLDB'+index} onClick={() =>setSelectedMovie(Movie.Id)}>Verwijder uit lijst {list.data.Name}</Button> : <></>}
          <hr key={'WLHR'+index}/>
        </div>)
      )}
      </div>
    );
  });

  const planBoardSelect = ((Id, Column)=>{

    return(
      <select value={Id+'-'+Column} onChange={updateTitleColumn}>
        {planCols.map(pC => (<option key={Id+'-'+pC} value={Id+'-'+pC}>{pC}</option>))}
      </select>

    );
  });

  const updateTitleColumn = ((e)=>{
    if(admin){
      var titlesTemp = titles;
      let titleId=e.target.value.substr(0,e.target.value.indexOf('-'));
      let column=e.target.value.substr(e.target.value.indexOf('-')+1);
      let pos = titles.map(e => e.Id.toString()).indexOf(titleId);

      titlesTemp[pos].Column = column;
      let cols = titlesTemp.map(e => ({titleId:e.Id, column:e.Column}));
      //console.log(cols);
      setTitles(titlesTemp);
      savePlan(cols);
      setUpdateTstamp(Date.now());
    }
  })

  const planBoardList = (() => {
    return(
      <div style={{ display: "flex" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {planCols.map((Col, index) =>(
            <Droppable key={Col} droppableId={Col}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                  <h2>{Col}</h2>
                  <p>{titles.filter(title=>title.Column===Col).map(title=>title.RunTime).length} ({titles.filter(title=>title.Column===Col).map(title=>title.RunTime).reduce((partial_sum, a) => partial_sum + a,0)} minuten)</p>
                  {titles.filter(movie => movie.Column === Col).map((Movie, index) => (
                    <Draggable key={Movie.Id} draggableId={Movie.Id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <h4>{Movie.Directors[0].Name} - {Movie.Title} ({Movie.Year})</h4>
                          {Movie.Genres}&nbsp;
                          {Movie.RunTime} minuten
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
    );
  });
//{admin ? <Form.Check type='checkbox' id='expandedCB' label='Planbord?' value='expandedCB' checked={planBoard} onChange={updatePlanBoard} /> : <></>}
  return (
    <>
    {list.data ? <h1>{list.data.Name}</h1>: <></>}
    <Form.Check type='checkbox' id='expandedCB' label='Planbord?' value='expandedCB' checked={planBoard} onChange={updatePlanBoard} />

    {planBoard ? planBoardList() : expandedList()}
    </>
  );
};

export default List;
